class CombiDiscountPage {
    constructor(type) {
        this._bannerID = 'combiDiscountBanner';
        this._type = type;
        this._element = document.getElementById(this._bannerID);
        const targetParent = document.getElementById('combi-discount-page');
        this._personalizationHandler = personalizationFactory({targetParent});
    }

    personalize() {
        if (!this._element) {
            return;
        }

        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = () => {
            if (xmlhttp.readyState == XMLHttpRequest.DONE) {
                if (xmlhttp.status == 200) {
                    this._element.outerHTML = xmlhttp.responseText;
                    this._personalizationHandler.attachResponseHandlers();
                    this.reAttachTooltipBehaviour();
                }
            }
        };

        xmlhttp.open(
            'POST',
            this._element.dataset.path + '?type=' + this._type,
            true
        );
        xmlhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xmlhttp.send();
    }

    reAttachTooltipBehaviour() {
        const element = $('#' + this._bannerID);
        const tooltipObject = new Tooltips();
        tooltipObject.initialize(element.find('.tooltip'));
    }
}

class CombiDiscountDefaultPage {
    constructor(type) {
        this._type = type;
        const targetParent = document.getElementById('combi-discount-default-page');
        this._personalizationHandler = personalizationFactory({targetParent});

        this._energy_button_id = 'energyOrderButton';
        this._mobile_button_id = 'mobileOrderButton';
        this._all_in_one_button_id = 'allInOneOrderButton';

        this._reaction_mappers = {
            Mobile: [this._all_in_one_button_id, this._energy_button_id],
            Energy: [this._all_in_one_button_id, this._mobile_button_id],
            AllInOne: [this._mobile_button_id, this._energy_button_id],
        };
    }

    personalize() {
        var response_triggers = this._reaction_mappers[this._type];
        if (!response_triggers) {
            throw new Error(`Unkown type ${this._type}`);
        }

        this._personalizationHandler.attachDataAttributeToElements(
            response_triggers
        );
        this._personalizationHandler.attachResponseHandlers();
    }
}

function addEnergyCombiDiscountPersonalization() {
    personalizationRegistry.add(new CombiDiscountPage('Energy'));
}

function addAllInOneCombiDiscountPersonalization() {
    personalizationRegistry.add(new CombiDiscountPage('AllInOne'));
}

function addMobileCombiDiscountPersonalization() {
    personalizationRegistry.add(new CombiDiscountPage('Mobile'));
}

function addDefaultEnergyCombiDiscountPersonalization() {
    personalizationRegistry.add(new CombiDiscountDefaultPage('Energy'));
}

function addDefaultAllInOneCombiDiscountPersonalization() {
    personalizationRegistry.add(new CombiDiscountDefaultPage('AllInOne'));
}

function addDefaultMobileCombiDiscountPersonalization() {
    personalizationRegistry.add(new CombiDiscountDefaultPage('Mobile'));
}